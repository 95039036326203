.talkCard {
  margin: 0 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.img {
  height: 50vh;
  width: 100%;
}

.title {
  padding-top: 3rem;
}
@media screen and (max-width: 600px) {
  .talkCard {
    width: auto;
  }
  .img {
    padding-top: 0.5rem;
  }
  .list {
    text-align: justify;
    text-justify: inter-word;
  }
}
