.page {
  border-radius: 1rem;
}

.cvSk {
  padding: 0.5rem;
}

.cv {
  padding: 0.5rem;
  padding-top: 1.5rem;
}
.doc {
  padding-top: 0.5rem;
}
.cvContainer {
  min-width: 100%;
  min-height: 100%;
}

.contact {
  align-self: start;
}
