.main {
  margin-top: -50rem;
  margin-bottom: 10rem;
  margin-inline: auto;
  width: 60%;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
  position: relative;
}

.section1 {
  margin: 0;
  width: 100%;
  height: 60rem;
  background-image: url("../../assets/attempt.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

section2 {
  margin: 0;
  width: 100%;
  height: 100%;
  position: relative;
}

@media (max-width: 900px) {
  .main {
    margin-top: -55rem;
    width: 90%;
  }
  .section1 {
    background-image: url("../../assets/layered-steps-haikei.svg");
  }
}
