.page {
  margin: 0 auto;
  padding: 1rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
}
@media screen and (min-width: 1200px) {
  .page {
    max-height: 40vh;
  }
}
