body {
  margin: 0;
  padding: 0;
  background-color: #d8dee9;
  font-family: -apple-system, "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 900px) {
  body {
    background-color: #2e3440;
  }
}
