.page {
  text-align: justify;
  margin: auto;
}
.stack {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.img {
  color: transparent;
  border-radius: 50%;
  margin: 0.75rem;
}
.title {
  margin-top: 1.5rem;
  padding: 1.5rem;
}

.page a {
  text-decoration: none;
  color: #b48ead;
}
.text {
  padding: 1rem;
}

@media screen and (max-width: 600px) {
  .title {
    padding: 3rem;
    font-weight: bold;
  }
}
@media (min-width: 1025px) {
  .page {
    font-size: 1.5rem;
  }
}
