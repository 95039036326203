.workCard {
  margin: 0 auto;
  display: flex;
  padding: 3rem;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}

.teachTitle {
  padding-top: 3rem;
}
@media screen and (max-width: 600px) {
  .workCard {
    padding: 0;
  }
}
